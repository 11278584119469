/* eslint-disable simple-import-sort/imports */
import { datadogRum } from "@datadog/browser-rum";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-fluid-table/dist/index.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/App.css";
import "./styles/wysiwyg-editor.css";
import "./styles/wysiwyg-toolbar.css";
import { getBranch } from "./constants/branch";
import OktaProvider from "./modules/OktaProvider";
import { initializeApi } from "./config/api";

// initialize DataDog APM for non-local
if (import.meta.env.VITE_ENV !== "local") {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "batter",
    env: import.meta.env.VITE_ENV,
    version: `${getBranch()}-${import.meta.env.VITE_COMMITHASH}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.startSessionReplayRecording();
}

// initialize interceptors
initializeApi();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <OktaProvider>
      <App />
    </OktaProvider>
  </React.StrictMode>
);
