import { ValueOpt } from "best-common-react";
import { titleCase } from "../utils/string";

const roles = [
  "BATTER_ADMIN",
  "BATTER_BROADCAST",
  "BATTER_BROADCAST_REQUESTER",
  "BATTER_BROADCAST_VIEWER",
  "BATTER_CLUB",
  "BATTER_EXTERNAL",
  "BATTER_FIELD",
  "BATTER_MINOR_CLUB",
  "BATTER_MINOR_SUPERVISOR",
  "BATTER_SCHEDULING",
  "BATTER_VIEWER",
  "BATTER_IGSP_ADMIN",
  "BATTER_IGSP_REQUESTER",
  "BATTER_IGSP_VIEWER",
  "BATTER_IGSP_REQUEST_APPROVER",
  "BATTER_IGSP_ENGINEERING_APPROVER"
] as const;
export type BatterRole = (typeof roles)[number];
export const BATTER_ROLES = roles as unknown as BatterRole[];

export const roleOptionCreator = (r: BatterRole): ValueOpt<BatterRole> => ({
  value: r,
  label: titleCase(r.replace(/^BATTER_/i, ""), { sep: "_" })
});

type UserConstantsType = {
  ROLE_OPTIONS: ValueOpt<BatterRole>[];
  CLUB_USERS: BatterRole[];
  MiLB_USERS: BatterRole[];
  IGSP_USERS: BatterRole[];
  ROLES: {
    SUPER: BatterRole;
    BROADCAST: BatterRole;
    BROADCAST_VIEWER: BatterRole;
    BROADCAST_REQUESTER: BatterRole;
    CLUB: BatterRole;
    EXTERNAL: BatterRole;
    FIELD: BatterRole;
    MINOR_CLUB: BatterRole;
    MINOR_SUPERVISOR: BatterRole;
    SCHEDULE: BatterRole;
    VIEWER: BatterRole;
    IGSP_ADMIN: BatterRole;
    IGSP_REQUESTER: BatterRole;
    IGSP_VIEWER: BatterRole;
    IGSP_REQUEST_APPROVER: BatterRole;
    IGSP_ENGINEERING_APPROVER: BatterRole;
  };
};

export const UserConstants: UserConstantsType = {
  ROLES: {
    SUPER: "BATTER_ADMIN",
    BROADCAST: "BATTER_BROADCAST",
    BROADCAST_VIEWER: "BATTER_BROADCAST_VIEWER",
    BROADCAST_REQUESTER: "BATTER_BROADCAST_REQUESTER",
    CLUB: "BATTER_CLUB",
    EXTERNAL: "BATTER_EXTERNAL",
    FIELD: "BATTER_FIELD",
    MINOR_CLUB: "BATTER_MINOR_CLUB",
    MINOR_SUPERVISOR: "BATTER_MINOR_SUPERVISOR",
    SCHEDULE: "BATTER_SCHEDULING",
    VIEWER: "BATTER_VIEWER",
    IGSP_ADMIN: "BATTER_IGSP_ADMIN",
    IGSP_REQUESTER: "BATTER_IGSP_REQUESTER",
    IGSP_VIEWER: "BATTER_IGSP_VIEWER",
    IGSP_REQUEST_APPROVER: "BATTER_IGSP_REQUEST_APPROVER",
    IGSP_ENGINEERING_APPROVER: "BATTER_IGSP_ENGINEERING_APPROVER"
  },
  ROLE_OPTIONS: [...roles]
    .filter(r => r !== "BATTER_ADMIN")
    .sort()
    .map(roleOptionCreator),
  CLUB_USERS: ["BATTER_CLUB", "BATTER_MINOR_CLUB"],
  MiLB_USERS: ["BATTER_MINOR_CLUB", "BATTER_MINOR_SUPERVISOR"],
  IGSP_USERS: [
    "BATTER_IGSP_ADMIN",
    "BATTER_IGSP_REQUESTER",
    "BATTER_IGSP_VIEWER",
    "BATTER_IGSP_REQUEST_APPROVER",
    "BATTER_IGSP_ENGINEERING_APPROVER"
  ]
};

export const LocalStorageConstants = {
  BATTER_ROLES: "batter-roles",
  BATTER_USERNAME: "batter-username",
  OKTA_TOKEN: "okta-token-storage"
};
