import {
  IgspClientBroadcaster,
  IgspContract,
  IgspCountry,
  IgspDecoderBrand,
  IgspDecoderModel,
  IgspJewelEvent,
  IgspNetwork,
  IgspRegion,
  IgspRequest,
  IgspRequestFilters,
  IgspShow,
  IgspTransmissionMethod,
  IgspTransmitCircuit,
  IgspTransmitDestination,
  IgspTransmitFormat,
  SaveIgspClientBroadcaster,
  SaveIgspContract,
  SaveIgspCountry,
  SaveIgspDecoderBrand,
  SaveIgspDecoderModel,
  SaveIgspJewelEvent,
  SaveIgspNetwork,
  SaveIgspRegion,
  SaveIgspShow,
  SaveIgspTransmissionMethod,
  SaveIgspTransmitCircuit,
  SaveIgspTransmitDestination,
  SaveIgspTransmitFormat
} from "../types/igsp";
import { generateQueryParams } from "../utils/http";
import { batterApi } from "./batter";

export const getIgspClientBroadcasters = async (active?: boolean) => {
  const result = await batterApi.get<{ broadcasters: IgspClientBroadcaster[] }>(
    `/v2/igsp/client/broadcasters${generateQueryParams({ active })}`
  );
  return result.data.broadcasters;
};

export const saveIgspClientBroadcaster = async (payload: SaveIgspClientBroadcaster, id?: number) => {
  const result = id
    ? await batterApi.put<IgspClientBroadcaster>(`/v2/igsp/client/broadcasters/${id}`, payload)
    : await batterApi.post<IgspClientBroadcaster>("/v2/igsp/client/broadcasters", payload);
  return result.data;
};

export const deleteIgspClientBroadcaster = async (id: number) => {
  const result = await batterApi.delete<IgspClientBroadcaster>(`/v2/igsp/client/broadcasters/${id}`);
  return result.data;
};

export const saveIgspContract = async (payload: SaveIgspContract, id?: number) => {
  const result = id
    ? await batterApi.put<IgspContract>(`/v2/igsp/contracts/${id}`, payload)
    : await batterApi.post<IgspContract>("/v2/igsp/contracts", payload);
  return result.data;
};

export const getIgspCountries = async (active?: boolean) => {
  const result = await batterApi.get<{ countries: IgspCountry[] }>(
    `/v2/igsp/countries${generateQueryParams({ active })}`
  );
  return result.data.countries;
};

export const saveIgspCountry = async (payload: SaveIgspCountry, id?: number) => {
  const result = id
    ? await batterApi.put<IgspCountry>(`/v2/igsp/countries/${id}`, payload)
    : await batterApi.post<IgspCountry>("/v2/igsp/countries", payload);
  return result.data;
};

export const deleteIgspCountry = async (id: number) => {
  const result = await batterApi.delete<IgspCountry>(`/v2/igsp/countries/${id}`);
  return result.data;
};

export const getIgspDecoderBrands = async (active?: boolean) => {
  const result = await batterApi.get<{ brands: IgspDecoderBrand[] }>(
    `/v2/igsp/decoder/brands${generateQueryParams({ active })}`
  );
  return result.data.brands;
};

export const saveIgspDecoderBrand = async (payload: SaveIgspDecoderBrand, id?: number) => {
  const result = id
    ? await batterApi.put<IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`, payload)
    : await batterApi.post<IgspDecoderBrand>("/v2/igsp/decoder/brands", payload);
  return result.data;
};

export const deleteIgspDecoderBrand = async (id: number) => {
  const result = await batterApi.delete<IgspDecoderBrand>(`/v2/igsp/decoder/brands/${id}`);
  return result.data;
};

export const getIgspDecoderModels = async (active?: boolean) => {
  const result = await batterApi.get<{ models: IgspDecoderModel[] }>(
    `/v2/igsp/decoder/models${generateQueryParams({ active })}`
  );
  return result.data.models;
};

export const saveIgspDecoderModel = async (payload: SaveIgspDecoderModel, id?: number) => {
  const result = id
    ? await batterApi.put<IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`, payload)
    : await batterApi.post<IgspDecoderModel>("/v2/igsp/decoder/models", payload);
  return result.data;
};

export const deleteIgspDecoderModel = async (id: number) => {
  const result = await batterApi.delete<IgspDecoderModel>(`/v2/igsp/decoder/models/${id}`);
  return result.data;
};

export const getIgspJewelEvents = async (active?: boolean) => {
  const result = await batterApi.get<{ events: IgspJewelEvent[] }>(`/v2/igsp/events${generateQueryParams({ active })}`);
  return result.data.events;
};

export const saveIgspJewelEvent = async (payload: SaveIgspJewelEvent, id?: number) => {
  const result = id
    ? await batterApi.put<IgspJewelEvent>(`/v2/igsp/events/${id}`, payload)
    : await batterApi.post<IgspJewelEvent>("/v2/igsp/events", payload);
  return result.data;
};

export const deleteIgspJewelEvent = async (id: number) => {
  const result = await batterApi.delete<IgspJewelEvent>(`/v2/igsp/events/${id}`);
  return result.data;
};

export const getIgspNetworks = async (active?: boolean) => {
  const result = await batterApi.get<{ networks: IgspNetwork[] }>(
    `/v2/igsp/networks${generateQueryParams({ active })}`
  );
  return result.data.networks;
};

export const saveIgspNetwork = async (payload: SaveIgspNetwork, id: number) => {
  const result = await batterApi.put<IgspNetwork>(`/v2/igsp/networks/${id}`, payload);
  return result.data;
};

export const getIgspRegions = async (active?: boolean) => {
  const result = await batterApi.get<{ regions: IgspRegion[] }>(`/v2/igsp/regions${generateQueryParams({ active })}`);
  return result.data.regions;
};

export const saveIgspRegion = async (payload: SaveIgspRegion, id?: number) => {
  const result = id
    ? await batterApi.put<IgspRegion>(`/v2/igsp/regions/${id}`, payload)
    : await batterApi.post<IgspRegion>("/v2/igsp/regions", payload);
  return result.data;
};

export const deleteIgspRegion = async (id: number) => {
  const result = await batterApi.delete<IgspRegion>(`/v2/igsp/regions/${id}`);
  return result.data;
};

export const getIgspShows = async (active?: boolean) => {
  const result = await batterApi.get<{ shows: IgspShow[] }>(`/v2/igsp/shows${generateQueryParams({ active })}`);
  return result.data.shows;
};

export const saveIgspShow = async (payload: SaveIgspShow, id?: number) => {
  const result = id
    ? await batterApi.put<IgspShow>(`/v2/igsp/shows/${id}`, payload)
    : await batterApi.post<IgspShow>("/v2/igsp/shows", payload);
  return result.data;
};

export const deleteIgspShow = async (id: number) => {
  const result = await batterApi.delete<IgspShow>(`/v2/igsp/shows/${id}`);
  return result.data;
};

export const getIgspTransmissionMethods = async (active?: boolean) => {
  const result = await batterApi.get<{ methods: IgspTransmissionMethod[] }>(
    `/v2/igsp/transmission/methods${generateQueryParams({ active })}`
  );
  return result.data.methods;
};

export const saveIgspTransmissionMethod = async (payload: SaveIgspTransmissionMethod, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`, payload)
    : await batterApi.post<IgspTransmissionMethod>("/v2/igsp/transmission/methods", payload);
  return result.data;
};

export const deleteIgspTransmissionMethod = async (id: number) => {
  const result = await batterApi.delete<IgspTransmissionMethod>(`/v2/igsp/transmission/methods/${id}`);
  return result.data;
};

export const getIgspTransmitCircuits = async (active?: boolean) => {
  const result = await batterApi.get<{ circuits: IgspTransmitCircuit[] }>(
    `/v2/igsp/transmit/circuits${generateQueryParams({ active })}`
  );
  return result.data.circuits;
};

export const saveIgspTransmitCircuit = async (payload: SaveIgspTransmitCircuit, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmitCircuit>(`/v2/igsp/transmit/circuits/${id}`, payload)
    : await batterApi.post<IgspTransmitCircuit>("/v2/igsp/transmit/circuits", payload);
  return result.data;
};

export const deleteIgspTransmitCircuit = async (id: number) => {
  const result = await batterApi.delete<IgspTransmitCircuit>(`/v2/igsp/transmit/circuits/${id}`);
  return result.data;
};

export const getIgspTransmitDestinations = async (active?: boolean) => {
  const result = await batterApi.get<{ destinations: IgspTransmitDestination[] }>(
    `/v2/igsp/transmit/destinations${generateQueryParams({ active })}`
  );
  return result.data.destinations;
};

export const saveIgspTransmitDestination = async (payload: SaveIgspTransmitDestination, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`, payload)
    : await batterApi.post<IgspTransmitDestination>("/v2/igsp/transmit/destinations", payload);
  return result.data;
};

export const deleteIgspTransmitDestination = async (id: number) => {
  const result = await batterApi.delete<IgspTransmitDestination>(`/v2/igsp/transmit/destinations/${id}`);
  return result.data;
};

export const getIgspTransmitFormats = async (active?: boolean) => {
  const result = await batterApi.get<{ formats: IgspTransmitFormat[] }>(
    `/v2/igsp/transmit/formats${generateQueryParams({ active })}`
  );
  return result.data.formats;
};

export const saveIgspTransmitFormat = async (payload: SaveIgspTransmitFormat, id?: number) => {
  const result = id
    ? await batterApi.put<IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`, payload)
    : await batterApi.post<IgspTransmitFormat>("/v2/igsp/transmit/formats", payload);
  return result.data;
};

export const deleteIgspTransmitFormat = async (id: number) => {
  const result = await batterApi.delete<IgspTransmitFormat>(`/v2/igsp/transmit/formats/${id}`);
  return result.data;
};

export const getIgspRequests = async (filters: IgspRequestFilters) => {
  const results = await batterApi.get<{ requests: IgspRequest[] }>(`/v2/igsp/requests${generateQueryParams(filters)}`);
  return results.data.requests;
};
