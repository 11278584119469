import { AccessToken } from "@okta/okta-auth-js";
import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { batterApi } from "../api/batter";
import { AUTH_ERROR_CODES } from "../constants/core";
import { SiteMap } from "../constants/route";
import { LocalStorageConstants } from "../constants/user";
import { oktaAuth } from "./okta";

type RequestConfig = InternalAxiosRequestConfig & { __isRetryRequest?: boolean };
let isRefreshing = false;
let failedQueue: { resolve: (_: string | null) => void; reject: (_: unknown) => void }[] = [];

// Helper to process the queue when refresh is done
const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const initializeApi = () => {
  // request interceptor
  batterApi.interceptors.request.use(config => {
    const tokenStr = localStorage.getItem(LocalStorageConstants.OKTA_TOKEN) || '{"accessToken": "", "idToken": "" }';
    const { accessToken = {} } = JSON.parse(tokenStr);

    const url = config?.url || "";
    if (url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
    }
    return config;
  });

  batterApi.interceptors.response.use(
    resp => resp || null,
    async (err: AxiosError) => {
      const request: RequestConfig | undefined = err.config;
      if (AUTH_ERROR_CODES.has(err.response?.status || 0) && !request?.__isRetryRequest) {
        // if there's no request, just throw the error
        if (!request) {
          throw err;
        }

        // initialize the promise
        if (isRefreshing) {
          // If token refresh is already happening, queue the request
          return new Promise<string | null>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then(token => {
            // Retry the original request with the new token
            request.headers.Authorization = `Bearer ${token}`;
            return batterApi(request);
          });
        }

        // eslint-disable-next-line no-console
        console.log("Token Expired Error. Will renew and retry...");
        request.__isRetryRequest = true;
        isRefreshing = true;

        try {
          const tokens = await oktaAuth.tokenManager.renew("accessToken");
          if (tokens) {
            const { accessToken } = tokens as AccessToken;
            request.headers.Authorization = `Bearer ${accessToken}`;

            // Resolve queued requests
            processQueue(null, accessToken);

            return batterApi.request(request);
          }
        } catch (error) {
          console.error(error);
          processQueue(error, null);
          localStorage.clear();
          location.href = SiteMap.LOGIN.path;
        } finally {
          isRefreshing = false;
        }
      }
      throw err;
    }
  );
};
