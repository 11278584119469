import axios from "axios";

export const batterApi = axios.create({
  baseURL: import.meta.env.VITE_SERVICES_URL
});

/**
 * NOTE: we should be pulling this from our own database
 * at some point soon
 */
export const statsApi = axios.create({
  baseURL: import.meta.env.VITE_STATS_URL
});
