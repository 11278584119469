import { lazy } from "react";
import { RoutePath } from "../types/route";
import { UserConstants } from "./user";

const {
  SUPER,
  BROADCAST,
  CLUB,
  EXTERNAL,
  FIELD,
  MINOR_CLUB,
  MINOR_SUPERVISOR,
  SCHEDULE,
  VIEWER,
  BROADCAST_VIEWER,
  BROADCAST_REQUESTER,
  IGSP_ADMIN,
  IGSP_REQUESTER
} = UserConstants.ROLES;

export const SiteMap = {
  LOGIN: { path: "/login", roles: [] } as RoutePath,
  LOGIN_CALLBACK: { path: "/login/callback", roles: [] } as RoutePath,
  BASE: { path: "/", roles: [] } as RoutePath,
  UNAUTHORIZED: { path: "/unauthorized", roles: [] } as RoutePath,
  ADMIN_BROADCAST_MAPPINGS: {
    path: "/admin/mappings/broadcast",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  ADMIN_MILB_MAPPINGS: {
    path: "/admin/mappings/milb",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER]
  } as RoutePath,
  ADMIN_BROADCAST_VALUES: {
    path: "/admin/broadcast",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  ADMIN_TEAM_VALUES: {
    path: "/admin/team",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER, SCHEDULE]
  } as RoutePath,
  ADMIN_DISTRO_LISTS: {
    path: "/admin/emails",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  ADMIN_REPORTS: {
    path: "/admin/reports",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER]
  } as RoutePath,
  ADMIN_SCHEDULE: {
    path: "/admin/schedule",
    page: () => lazy(() => import("../pages/AdminPage")),
    roles: [SUPER, SCHEDULE]
  } as RoutePath,
  IGSP_SETTINGS: {
    path: "/admin/igsp/settings",
    page: () => lazy(() => import("../pages/admin/IgspSettingsPage")),
    roles: [SUPER, IGSP_ADMIN]
  } as RoutePath,
  IGSP_CLIENT_MAPPINGS: {
    path: "/admin/igsp/mappings",
    page: () => lazy(() => import("../pages/admin/IgspMappingsPage")),
    roles: [SUPER, IGSP_ADMIN]
  } as RoutePath,
  DOMESTIC_DOCUMENTS: {
    path: "/documents/domestic",
    page: () => lazy(() => import("../pages/documents/DomesticPage")),
    roles: [SUPER, BROADCAST, SCHEDULE, BROADCAST_VIEWER, CLUB, MINOR_CLUB, MINOR_SUPERVISOR, VIEWER]
  } as RoutePath,
  IGSP_DOCUMENTS: {
    path: "/documents/igsp",
    page: () => lazy(() => import("../pages/documents/IgspPage")),
    roles: [SUPER]
  } as RoutePath,
  EVENTS: {
    path: "/events",
    page: () => lazy(() => import("../pages/EventsPage")),
    roles: [SUPER, CLUB, FIELD, MINOR_CLUB, MINOR_SUPERVISOR, SCHEDULE]
  } as RoutePath,
  REPORTS: {
    path: "/reports",
    page: () => lazy(() => import("../pages/ReportsPage")),
    roles: [
      SUPER,
      BROADCAST,
      BROADCAST_REQUESTER,
      BROADCAST_VIEWER,
      CLUB,
      EXTERNAL,
      FIELD,
      MINOR_CLUB,
      MINOR_SUPERVISOR,
      SCHEDULE,
      VIEWER
    ]
  } as RoutePath,
  SCHEDULE: {
    path: "/schedule",
    page: () => lazy(() => import("../pages/SchedulePage")),
    roles: [SUPER, BROADCAST, BROADCAST_VIEWER, CLUB, EXTERNAL, FIELD, MINOR_CLUB, MINOR_SUPERVISOR, SCHEDULE, VIEWER]
  } as RoutePath,
  IMPORTS: {
    path: "/imports",
    page: () => lazy(() => import("../pages/ImportsPage")),
    roles: [SUPER, BROADCAST, SCHEDULE]
  } as RoutePath,
  DOMESTIC_REQUESTS: {
    path: "/requests/domestic/view",
    page: () => lazy(() => import("../pages/requests/DomesticViewPage")),
    roles: [SUPER, BROADCAST, BROADCAST_REQUESTER]
  } as RoutePath,
  DOMESTIC_REQUEST_APPROVAL: {
    path: "/requests/domestic/approval",
    page: () => lazy(() => import("../pages/requests/DomesticManagementPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  IGSP_REQUESTS: {
    path: "/requests/igsp/view",
    page: () => lazy(() => import("../pages/requests/IgspPage")),
    roles: [SUPER, IGSP_ADMIN, IGSP_REQUESTER]
  } as RoutePath,
  MEMO_SEARCH: {
    path: "/memo/search",
    page: () => lazy(() => import("../pages/ChangeMemoPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  MEMO_HISTORY: {
    path: "/memo/history",
    page: () => lazy(() => import("../pages/ChangeMemoPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath,
  MEMO_TEMPLATE: {
    path: "/memo/template",
    page: () => lazy(() => import("../pages/ChangeMemoPage")),
    roles: [SUPER, BROADCAST]
  } as RoutePath
};
