import { DateTime } from "luxon";
import { BatterDate, BatterDateTime, BatterTime } from "../types/core";

/**
 * returns a formatted date
 * @param arg date in the format YYYY-MM-DD, JS Date, or BatterDate
 * @param format the format string
 * @param message a default message (optional)
 * @returns a string
 */
export const formatDate = (arg: string | Date | BatterDate | null | undefined, format: string, message = "") => {
  let dt: DateTime | null = null;
  if (typeof arg === "string") {
    // if the string is YYYY-MM-DD, then from SQL
    // if the string is HH:mm, then from SQL
    // if the date is a dateTime, then we have to worry about timeZones
    dt = DateTime.fromSQL(arg);
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else if (arg instanceof BatterDate) {
    dt = DateTime.fromObject(arg);
  }

  return dt?.toFormat(format) || message;
};

export type FormatTimeOptions = {
  input?: string;
  defaultValue?: string;
};

/**
 * returns a formatted time
 * @param arg time string in the HH:mm format or BatterTime
 * @param format the format string
 * @param options formatting options.
 * @returns a string
 */
export const formatTime = (
  arg: string | Date | BatterTime | null | undefined,
  format: string,
  options: FormatTimeOptions = {}
) => {
  const { defaultValue = "", input = "HH:mm:ss" } = options;
  if (!arg) {
    return defaultValue;
  }

  let dt: DateTime;
  if (typeof arg === "string") {
    dt = DateTime.fromFormat(arg, input);
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else {
    dt = DateTime.fromObject(arg);
  }

  return dt.toFormat(format) || defaultValue;
};

export type FormatTZOptions = {
  defaultValue?: string;
};

export const formatTimezone = (arg: string | null | undefined, format: string, options: FormatTZOptions = {}) => {
  const { defaultValue = "" } = options;
  if (!arg) {
    return defaultValue;
  }

  return DateTime.now().setZone(arg).toFormat(format);
};

export type DateTimeFormatOptions = {
  /**
   * an optional boolean that turns the result to system zone (default: false)
   */
  toLocal?: boolean;
  /**
   * an optional zone to convert the string to another timeZone
   */
  toZone?: string;
};

/**
 * returns a formatted dateTime
 * @param arg dateTime string in the ISO format, Date, or BatterDateTime
 * @param format the format string
 * @param options format options
 * @returns a string
 */
export const formatDateTime = (
  arg: string | Date | BatterDateTime,
  format: string,
  options: DateTimeFormatOptions = {}
) => {
  // instance
  let dt: DateTime | null = null;
  const { toLocal = false, toZone } = options;

  // do the formatting
  if (typeof arg === "string") {
    dt = toLocal ? DateTime.fromISO(arg) : DateTime.fromISO(arg, { setZone: !toZone, zone: toZone });
  } else if (arg instanceof Date) {
    dt = DateTime.fromJSDate(arg);
  } else {
    dt = DateTime.fromObject(arg);
  }
  return dt.toFormat(format);
};

export const dateFromBatterDate = (arg: BatterDate) => {
  const res = new Date();
  res.setHours(0, 0, 0, 0);
  res.setFullYear(arg.year);
  res.setMonth(arg.month - 1);
  res.setDate(arg.day);
  return res;
};

export const dateFromDateAndTime = (date: Date, time: Date) => {
  const res = new Date(date.getTime());
  res.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  return res;
};

const jsDateFromString = (arg: string, format: string) => DateTime.fromFormat(arg, format).toJSDate();

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @param format optional format
 * @returns JS Date
 */
export const dateFromDateString = (arg: string, format = "yyyy-MM-dd") => DateTime.fromFormat(arg, format).toJSDate();

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @param ignoreTz whether or not to ignore the tz in the arg
 * @returns JS Date
 */
export const dateFromDateTimeString = (arg: string, ignoreTz = false) => {
  if (ignoreTz) {
    // the arg itself cannot have a timeZone at the end
    const newArg = arg.replace(/[-+]\d{4}$/i, "");
    return jsDateFromString(newArg, "yyyy-MM-dd'T'HH:mm:ss");
  }

  return jsDateFromString(arg, "yyyy-MM-dd'T'HH:mm:ssZZZ");
};

/**
 * gets a JS Date object from a string
 * @param arg input string
 * @returns JS Date
 */
export const dateFromTimeString = (arg: string) => {
  const now = formatDate(new Date(), "yyyy-MM-dd");
  return dateFromDateTimeString(`${now}T${arg}`, true);
};

export const convertToTimezone = (arg: string | Date, to: string) => {
  const dt = typeof arg === "string" ? DateTime.fromISO(arg, { setZone: true }) : DateTime.fromJSDate(arg);
  return new Date(dt.setZone(to).toFormat("yyyy-MM-dd'T'HH:mm:ss"));
};

const isStringValid = (arg: string | undefined, format: string) => {
  return !!arg && DateTime.fromFormat(arg, format).isValid;
};

/**
 * checks if a string matches the pattern 'M/d/yyyy' & that
 * the actual date is a valid date
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid date
 */
export const isDateStringValid = (arg?: string) => isStringValid(arg, "M/d/yyyy");

/**
 * checks if a string matches the pattern 'h:mm a' & that
 * the actual time is a valid time
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid time
 */
export const isTimeStringValid = (arg?: string) => isStringValid(arg, "h:mm a");

/**
 * checks if a string matches the pattern 'M/d/yyyy h:mm a' & that
 * the actual dateTime is a valid dateTime
 * @param arg the string to check
 * @returns a boolean whther or not the string is a valid dateTime
 */
export const isDateTimeStringValid = (arg?: string) => isStringValid(arg, "M/d/yyyy h:mm a");

/**
 * add days to a Date
 * @param date Date
 * @param days number of days
 * @returns Date
 */
export const addDays = (date: Date, days: number) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + days);
  return copy;
};

/**
 * add years to a Date
 * @param date Date
 * @param years number of years
 * @returns Date
 */
export const addYears = (date: Date, years: number) => {
  const copy = new Date(date.getTime());
  copy.setFullYear(date.getFullYear() + years);
  return copy;
};

/**
 * get the date at the start of the week (Sunday)
 * @param date date to checks
 * @returns the date at the start of the week
 */
export const getStartOfWeek = (date: Date) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() - date.getDay());
  return copy;
};

/**
 * get the date at the end of the week (Saturday)
 * @param date date to checks
 * @returns the date at the end of the week
 */
export const getEndOfWeek = (date: Date) => {
  const copy = new Date(date.getTime());
  copy.setDate(date.getDate() + (6 - date.getDay()));
  return copy;
};

export const getClosestDayOfWeek = (date: Date, dayNum: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
  if (dayNum === date.getDay()) {
    return date;
  }

  return addDays(date, 7 + (dayNum - date.getDay()));
};
