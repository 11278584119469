import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";

type LayoutState = ReactContext<Action> & {
  sidebarVisible: boolean;
};

const initialState: LayoutState = {
  dispatch: () => {},
  sidebarVisible: true
};

export const LayoutContext = createContext(initialState);

const reducer = (state: LayoutState, action: Action): LayoutState => {
  // logic
  switch (action.type) {
    case "toggleSidebar": {
      return { ...state, sidebarVisible: !state.sidebarVisible };
    }
    default:
      return state;
  }
};

export const LayoutProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LayoutContext.Provider value={{ ...state, dispatch }}>{children}</LayoutContext.Provider>;
};
