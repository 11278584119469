import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import { GameConstants } from "../constants/game";
import { EventType } from "../types/event";
import { GameStatus, GameType } from "../types/game";
import {
  getLeagueName,
  GroupDivisionOption,
  GroupLeagueOption,
  League,
  LeagueDivision,
  LeagueOption
} from "../types/league";
import { Action, ReactContext, ReactProps } from "../types/react";
import { Region } from "../types/region";
import { Season } from "../types/schedule";
import { GroupTeamOption, Team } from "../types/team";
import { TimezoneMap } from "../types/timezones";
import { Venue } from "../types/venue";
import { divisionOptionCreator, teamOptionCreator, timezoneOptionCreator } from "../utils/form";
import { listToDict } from "../utils/list";
import { getCurrentPostSeason, getCurrentSeason, getCurrentSpringTraining } from "../utils/season";

type LookupAction = Action & {
  teams?: Team[];
  teamInfo?: Team[];
  seasons?: Season[];
  leagues?: League[];
  eventTypes?: EventType[];
  gameTypes?: GameType[];
  gameYears?: number[];
  gameStatuses?: GameStatus[];
  leagueDivisions?: LeagueDivision[];
  regions?: Region[];
  venues?: Venue[];
  timezones?: TimezoneMap[];
};

type LookupState = ReactContext<LookupAction> & {
  loading: boolean;
  teams: Team[];
  teamInfo: Team[];
  teamsById: { [x: number]: Team };
  teamOptions: GroupTeamOption[];
  mlbTeamOptions: GroupTeamOption[];
  milbTeamOptions: GroupTeamOption[];
  seasons: Season[];
  mlbSeasons: Season[];
  milbSeasons: Season[];
  currentMLBSeason: Season | null;
  currentMiLBSeason: Season | null;
  currentMLBPostSeason: Season | null;
  currentMLBSpringTraining: Season | null;
  seasonByYearAndSport: { [x: string]: Season };
  leagues: League[];
  leaguesById: { [x: number]: League };
  leagueOptions: GroupLeagueOption[];
  mlbLeagueOptions: GroupLeagueOption[];
  milbLeagueOptions: GroupLeagueOption[];
  eventTypes: EventType[];
  eventTypeOptions: ValueOpt<number>[];
  gameTypes: GameType[];
  gameTypeOptions: ValueOpt<string>[];
  gameTypeByCode: { [x: string]: GameType };
  gameYears: number[];
  gameYearOptions: ValueOpt<number>[];
  gameStatuses: GameStatus[];
  gameStatusByCode: { [x: string]: GameStatus };
  gameStatusOptions: ValueOpt<string>[];
  leagueDivisions: LeagueDivision[];
  leagueDivisionOptions: GroupDivisionOption[];
  leagueDivisionMap: { [x: number]: LeagueDivision };
  regions: Region[];
  regionOptions: ValueOpt<number>[];
  venues: Venue[];
  venueOptions: ValueOpt<number>[];
  venuesById: { [x: number]: Venue };
  timezones: TimezoneMap[];
  timezonesById: { [x: number]: TimezoneMap };
  timezoneOptions: ValueOpt<number>[];
};

const initialState: LookupState = {
  dispatch: () => {},
  loading: false,
  teams: [],
  teamsById: {},
  teamInfo: [],
  teamOptions: [],
  mlbTeamOptions: [],
  milbTeamOptions: [],
  seasons: [],
  mlbSeasons: [],
  milbSeasons: [],
  currentMLBSeason: null,
  currentMiLBSeason: null,
  currentMLBPostSeason: null,
  currentMLBSpringTraining: null,
  seasonByYearAndSport: {},
  leagues: [],
  leaguesById: {},
  leagueOptions: [],
  mlbLeagueOptions: [],
  milbLeagueOptions: [],
  eventTypes: [],
  eventTypeOptions: [],
  gameTypes: [],
  gameTypeOptions: [],
  gameTypeByCode: {},
  gameYears: [],
  gameYearOptions: [],
  gameStatuses: [],
  gameStatusByCode: {},
  gameStatusOptions: [],
  leagueDivisions: [],
  leagueDivisionOptions: [],
  leagueDivisionMap: {},
  regions: [],
  regionOptions: [],
  venues: [],
  venueOptions: [],
  venuesById: {},
  timezones: [],
  timezonesById: {},
  timezoneOptions: []
};

export const LookupContext = createContext(initialState);

const leagueOptionCreator = (l: League): LeagueOption => ({
  value: l.id,
  label: getLeagueName(l),
  league: l
});

const createLeagueOptions = (leagues: League[]): Partial<LookupState> => {
  // instance
  const { MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A } = GameConstants.SportIds;
  const baseSportIds = new Set([MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A]);

  // create leagues map
  const leaguesById = listToDict(leagues, l => l.id);

  // create options
  const mlbLeaguesOptions = leagues.filter(l => l.sportId === MLB).map(leagueOptionCreator);
  const tripleALeagueOptions = leagues.filter(l => l.sportId === TRIPLE_A).map(leagueOptionCreator);
  const doubleALeagueOptions = leagues.filter(l => l.sportId === DOUBLE_A).map(leagueOptionCreator);
  const highALeagueOptions = leagues.filter(l => l.sportId === HIGH_A).map(leagueOptionCreator);
  const singleALeagueOptions = leagues.filter(l => l.sportId === SINGLE_A).map(leagueOptionCreator);

  // create groups
  const mlbLeagueGroup = [{ label: "Major League Baseball", options: mlbLeaguesOptions }];
  const milbLeagueGroup = [
    { label: "Triple-A", options: tripleALeagueOptions },
    { label: "Double-A", options: doubleALeagueOptions },
    { label: "High-A", options: highALeagueOptions },
    { label: "Single-A", options: singleALeagueOptions }
  ];

  // for each unique sport, group the rest of the leagues
  const remainingSportIds = new Set(leagues.filter(l => !baseSportIds.has(l.sportId)).map(l => l.sportId));
  const remainingGroup = [...remainingSportIds].sort().map(sportId => {
    const remaining = leagues.filter(l => l.sportId === sportId);

    // get the sportName from the first team
    const sportName = remaining[0].sportName;
    return { label: sportName, options: remaining.map(leagueOptionCreator) };
  });

  return {
    leagues,
    leaguesById,
    leagueOptions: [...mlbLeagueGroup, ...milbLeagueGroup, ...remainingGroup],
    mlbLeagueOptions: mlbLeagueGroup,
    milbLeagueOptions: milbLeagueGroup
  };
};

const createSeasonOptions = (seasons: Season[]): Partial<LookupState> => ({
  seasons,
  mlbSeasons: seasons.filter(s => s.sport === "MLB"),
  milbSeasons: seasons.filter(s => s.sport === "MiLB"),
  currentMLBSeason: getCurrentSeason("MLB", seasons),
  currentMiLBSeason: getCurrentSeason("MiLB", seasons),
  currentMLBPostSeason: getCurrentPostSeason("MLB", seasons),
  currentMLBSpringTraining: getCurrentSpringTraining("MLB", seasons),
  seasonByYearAndSport: seasons.reduce((pv, c) => ({ ...pv, [`${c.year}-${c.sport}`]: c }), {})
});

const createTeamOptions = (teams: Team[], leagues: League[]): Partial<LookupState> => {
  // instance
  const { MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A } = GameConstants.SportIds;
  const baseSportIds = new Set([MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A]);

  // divide teams by placeholders
  const activeTeams = teams.filter(t => !t.isPlaceholder);
  const placeholders = teams.filter(t => t.isPlaceholder);

  // create the MLB options
  const mlbTeamOptions = activeTeams.filter(t => t.sportId === MLB).map(teamOptionCreator);
  const mlbPlaceHolderOptions = placeholders.filter(t => t.sportId === MLB).map(teamOptionCreator);

  // create options for each MiLB league
  const tripleATeamOptions = activeTeams.filter(t => t.sportId === TRIPLE_A).map(teamOptionCreator);
  const doubleATeamOptions = activeTeams.filter(t => t.sportId === DOUBLE_A).map(teamOptionCreator);
  const highATeamOptions = activeTeams.filter(t => t.sportId === HIGH_A).map(teamOptionCreator);
  const singleATeamOptions = activeTeams.filter(t => t.sportId === SINGLE_A).map(teamOptionCreator);
  const tripleAPlaceholders = placeholders.filter(t => t.sportId === TRIPLE_A).map(teamOptionCreator);
  const doubleAPlaceholders = placeholders.filter(t => t.sportId === DOUBLE_A).map(teamOptionCreator);
  const highAPlaceholders = placeholders.filter(t => t.sportId === HIGH_A).map(teamOptionCreator);
  const singleAPlaceholders = placeholders.filter(t => t.sportId === SINGLE_A).map(teamOptionCreator);

  /// create groups
  const mlbGroup = [{ label: "Major League Baseball", options: [...mlbTeamOptions, ...mlbPlaceHolderOptions] }];
  const milbGroup = [
    { label: "Triple-A", options: [...tripleATeamOptions, ...tripleAPlaceholders] },
    { label: "Double-A", options: [...doubleATeamOptions, ...doubleAPlaceholders] },
    { label: "High-A", options: [...highATeamOptions, ...highAPlaceholders] },
    { label: "Single-A", options: [...singleATeamOptions, ...singleAPlaceholders] }
  ];

  // get remaining sports
  const remainingSportIds = new Set(teams.filter(t => !!t.sportId && !baseSportIds.has(t.sportId)).map(t => t.sportId));

  // if the leagues are initialized, group teams by league, otherwise by sport
  const remainingGroups = leagues.length
    ? (leagues
        .filter(l => remainingSportIds.has(l.sportId))
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(league => {
          const remainingActive = activeTeams.filter(t => t.leagueId === league.id);
          const remainingPlaceholder = placeholders.filter(t => t.leagueId === league.id);

          return { label: league.name, options: [...remainingActive, ...remainingPlaceholder].map(teamOptionCreator) };
        }) as GroupTeamOption[])
    : ([...remainingSportIds].sort().map(sportId => {
        const remainingActive = activeTeams.filter(t => t.sportId === sportId);
        const remainingPlaceholder = placeholders.filter(t => t.sportId === sportId);

        // get the sportName from the first team
        const sportName = (remainingActive[0] || remainingPlaceholder[0]).sportName;
        return { label: sportName, options: [...remainingActive, ...remainingPlaceholder].map(teamOptionCreator) };
      }) as GroupTeamOption[]);

  return {
    teams,
    teamsById: listToDict(teams, t => t.id),
    teamOptions: [...mlbGroup, ...milbGroup, ...remainingGroups],
    mlbTeamOptions: mlbGroup,
    milbTeamOptions: milbGroup
  };
};

const createDivisionOptions = (divisions: LeagueDivision[], leagues: League[]): Partial<LookupState> => {
  // instance
  const { MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A } = GameConstants.SportIds;
  const baseSportIds = new Set([MLB, TRIPLE_A, DOUBLE_A, HIGH_A, SINGLE_A]);

  // create leagues map
  const leaguesById = listToDict(leagues, l => l.id);

  // options
  const mlbDivisionOptions = divisions.filter(d => leaguesById[d.leagueId]?.sportId === MLB).map(divisionOptionCreator);
  const tripleADivisionOptions = divisions
    .filter(d => leaguesById[d.leagueId]?.sportId === TRIPLE_A)
    .map(divisionOptionCreator);
  const doubleADivisionOptions = divisions
    .filter(d => leaguesById[d.leagueId]?.sportId === DOUBLE_A)
    .map(divisionOptionCreator);
  const highADivisionOptions = divisions
    .filter(d => leaguesById[d.leagueId]?.sportId === HIGH_A)
    .map(divisionOptionCreator);
  const singleADivisionOptions = divisions
    .filter(d => leaguesById[d.leagueId]?.sportId === SINGLE_A)
    .map(divisionOptionCreator);

  // create groups
  const mlbDivisionGroup = [{ label: "Major League Baseball", options: mlbDivisionOptions }];
  const milbDivisionGroup = [
    { label: "Triple-A", options: tripleADivisionOptions },
    { label: "Double-A", options: doubleADivisionOptions },
    { label: "High-A", options: highADivisionOptions },
    { label: "Single-A", options: singleADivisionOptions }
  ];

  // for each unique sport, group the rest of the leagues
  const remainingSportIds = new Set(leagues.filter(l => !baseSportIds.has(l.sportId)).map(l => l.sportId));
  const remainingGroup = [...remainingSportIds].sort().map(sportId => {
    const remaining = divisions.filter(d => leaguesById[d.leagueId]?.sportId === sportId);

    // get the sportName from the first team
    const sportName = leaguesById[remaining[0]?.leagueId]?.sportName;
    return { label: sportName, options: remaining.map(divisionOptionCreator) };
  });

  return {
    leagueDivisions: divisions,
    leagueDivisionMap: listToDict(divisions, i => i.id),
    leagueDivisionOptions: [...mlbDivisionGroup, ...milbDivisionGroup, ...remainingGroup]
  };
};

const createTimezoneOptions = (timezones: TimezoneMap[]): Partial<LookupState> => {
  const timezoneMap = listToDict(timezones, t => t.id);
  const tzOptions = timezones.map(timezoneOptionCreator);
  return {
    timezonesById: timezoneMap,
    timezoneOptions: tzOptions
  };
};

const reducer = (state: LookupState, action: LookupAction): LookupState => {
  const {
    eventTypes = [],
    gameStatuses = [],
    gameTypes = [],
    gameYears = [],
    leagues = [],
    leagueDivisions = [],
    regions = [],
    seasons = [],
    teams = [],
    timezones = [],
    venues = [],
    teamInfo = []
  } = action;

  switch (action.type) {
    case "setEventTypes": {
      return {
        ...state,
        eventTypes,
        eventTypeOptions: eventTypes.map(e => ({ value: e.id, label: e.value }))
      };
    }
    case "setGameStatuses": {
      return {
        ...state,
        gameStatuses,
        gameStatusByCode: listToDict(gameStatuses, s => s.statusCode),
        gameStatusOptions: [
          {
            label: "Scheduled & Complete",
            value: GameConstants.GameStatus.COMPLETE
          },
          ...gameStatuses.map(g => ({ label: g.description, value: g.statusCode }))
        ]
      };
    }
    case "setGameTypes": {
      return {
        ...state,
        gameTypes,
        gameTypeOptions: gameTypes.map(g => ({ label: g.description, value: g.code })),
        gameTypeByCode: listToDict(gameTypes, t => t.code)
      };
    }
    case "setGameYears": {
      const sortedYears = gameYears.sort().reverse();
      return {
        ...state,
        gameYears: sortedYears,
        gameYearOptions: sortedYears.map(y => ({ label: y.toString(), value: y }))
      };
    }
    case "setLeagues": {
      return {
        ...state,
        ...createLeagueOptions(leagues),
        ...createTeamOptions(state.teams, leagues),
        ...createDivisionOptions(state.leagueDivisions, leagues)
      };
    }
    case "setLeagueDivisions": {
      return {
        ...state,
        ...createDivisionOptions(leagueDivisions, state.leagues)
      };
    }
    case "setRegions": {
      return {
        ...state,
        regions,
        regionOptions: regions.map(r => ({ label: `${r.stLeagueName} ${r.regionName}`.trim(), value: r.id }))
      };
    }
    case "setSeasons":
      return {
        ...state,
        ...createSeasonOptions(seasons)
      };
    case "setTeams":
      return {
        ...state,
        ...createTeamOptions(teams, state.leagues)
      };
    case "setTimezones":
      return {
        ...state,
        ...createTimezoneOptions(timezones)
      };
    case "setVenues": {
      return {
        ...state,
        venues,
        venuesById: listToDict(venues, v => v.id),
        venueOptions: venues.map(v => ({ label: v.name, value: v.id }))
      };
    }
    case "setTeamInfo": {
      return { ...state, teamInfo };
    }
    default:
      return state;
  }
};

export const LookupProvider = ({ children }: ReactProps) => {
  // hooks
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LookupContext.Provider value={{ ...state, dispatch }}>{children}</LookupContext.Provider>;
};
